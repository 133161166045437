import React from 'react';
import {Box, Grommet} from 'grommet';

const theme = {
	global: {
		font: {
			family: "Roboto",
			size: "18px",
			height: "20px",
		},
	},
};

function App() {
	return (
		<Grommet full theme={theme}>
			<Box>Linguome</Box>
		</Grommet>
	);
}

export default App;
